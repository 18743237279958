<template>
  <v-container>
    <v-row>
      <v-col md="8">
        <v-card
      outlined
      class="pa-5 d-flex justify-space-between"
    >
      <div class="">
        <div class="mb-5">
          <h2 class="headline text-capitalize mb-2">
            {{ conferenceCallDetails.title }}
          </h2>
          <div class="body">Status: {{ conferenceCallDetails.status }}</div>
          <div class="body">Approved Date: {{ conferenceCallDetails.approvedDate ? formatDate(conferenceCallDetails.approvedDate, 'Do MMM YYYY hh:mm A') : '-'}}</div>
         
          <div class="body">
            Preferrerd Date:
            {{ formatDate(conferenceCallDetails.preferredDate, 'Do MMM YYYY hh:mm A') }}
          </div>
        </div>

        <div class="body">Area: {{ conferenceCallDetails.area }}</div>
        <div class="body">
          Inquiry Type: {{ conferenceCallDetails.inquiryType }}
        </div>
        <div class="body">
          Description: {{ conferenceCallDetails.description }}
        </div>
        <div class="body">
          Link:
          <v-btn
      v-if="conferenceCallDetails.link"
      flat
      color="blue"
      x-small
      rounded
      :href="conferenceCallDetails.link"
      target="_blank"
    >
      Click Here
    </v-btn>
    
    <span v-else>
      -
    </span>
        </div>

        <div class="body">Remarks: {{ conferenceCallDetails.remarks }}</div>
      </div>
      <div class="action-btns">
        <v-btn
          @click="approveConferenceDialog = true"
          v-if="conferenceCallDetails.status == 'Pending'"
          color="primary"
        >
          <v-icon class="pr-3">mdi-check</v-icon>
          Approve</v-btn
        >
        <v-btn
          @click="markCompleted"
          color="primary"
          v-if="conferenceCallDetails.status == 'Called'"
        >
          <v-icon class="pr-3">mdi-check</v-icon>
          Mark Completed</v-btn
        >
      </div>
    </v-card>
      </v-col>
      <v-col md="4">
        <v-card  outlined
      class="pa-5 mx-auto">
      
      <h2 class="headline text-capitalize mb-2" v-if="conferenceCallDetails.customer">
           Customer Details
          </h2>
            <div>
               Name: {{conferenceCallDetails.customer.name}}
            </div>
            <div>
                Email: {{conferenceCallDetails.customer.email}}
            </div>
            <div>
                Phone: {{conferenceCallDetails.customer.phone}}
            </div>

            <h2 class="headline text-capitalize mt-5 mb-2">
            Assigned To
          </h2>
            <div>
               Name: {{conferenceCallDetails.assinedTo.name}}
            </div>
            <div>
                Email: {{conferenceCallDetails.assinedTo.email}}
            </div>
            <div>
                Phone: {{conferenceCallDetails.assinedTo.phone}}
            </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="approveConferenceDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h5"> Approve Conference Call </v-card-title>

        <v-select
          :items="expertList"
          item-text="name"
          item-value="id"
          filled
          label="Choose Expert To Assign"
          v-model="approveConferenceDetails.expertId"
        ></v-select>

        <v-datetime-picker label="Select Datetime" v-model="approveConferenceDetails.approvedDate">
          <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
           </v-datetime-picker>
        <v-text-field
          label="Conference Link"
          v-model="approveConferenceDetails.link"
        ></v-text-field>
        <v-text-field
          label="Remarks"
          v-model="approveConferenceDetails.remarks"
        ></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="approveConferenceDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="approve"> Approve </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      approveConferenceDialog: false,
      datePickerMenu: false,
      conferenceCallDetails: {},
      expertList: [],
      approveConferenceDetails: {
        approvedDate: "",
        remarks: "",
        id: this.$route.params.id,
      },
    };
  },
  methods: {
    getConferenceCallById() {
      this.$General
        .getVideoConferenceById(this.$route.params.id)
        .then((data) => {
          this.conferenceCallDetails = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getExpertList() {
      this.$Expert
        .getExpertList()
        .then((data) => {
          this.expertList = data.data.data.list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    approve() {
      this.$General
        .approveConference(this.approveConferenceDetails)
        .then((data) => {
          this.$toastr.success("Conference Call Approved Successfully");
          this.approveConferenceDialog = false;
          this.getConferenceCallById();
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
        });
    },
    markCompleted() {
      this.$General
        .approveConferenceCallCompletion(this.$route.params.id)
        .then((data) => {
          this.$toastr.success("This item has been marked complete");
          this.getConferenceCallById();
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
        });
    },
  },

  mounted() {
    this.getConferenceCallById();
    this.getExpertList();
  },
};
</script>


